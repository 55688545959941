@tailwind base;
@tailwind components;
@tailwind utilities;

body {  
  color: rgb(var(--foreground-rgb));
  background: #fff;
  font-family: montserrat; 

}

@layer utilities {
  .text-balance {
    text-wrap: balance; 
  }
}


a:hover{color:#001aff; text-decoration: underline;}

.swiper-pagination {
  text-align: right !important;  
  padding-right: 120px !important;
  margin-bottom: 0px;
}

@media only screen and (max-width: 1199px) {
  .swiper-pagination {
    text-align: center !important;
    padding-right: 0px !important;
    margin-bottom: -10px;
  }
} 


.swiper-pagination-bullet {
background-color:transparent  !important;
border: solid 1px #fff;
opacity: 1 !important;
width: 18px !important;
height: 18px !important;
}

.swiper-pagination-bullet-active {

  background-color: #fff !important;
  border: solid 1px #fff;
}
.swiper-button-prev{display: none !important;}
.swiper-button-prev, .swiper-button-next{color:#fff !important}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0 !important;
}

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: 'next';
  color: transparent;
  background: url('assets/arrow-right.png') no-repeat center center; height: 70px; 
  margin-right:50px
}
@media screen and (max-width: 1199px) {
  .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  margin-right:0px;
  height: 60px; 
  }
}
.oqueslider .swiper-button-next:after, .oqueslider  .swiper-rtl .swiper-button-prev:after {

}



.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after { 
  content: 'prev';
  color: transparent;
  background: url('assets/arrow-left.png') no-repeat center center; height: 120px; 
  margin-left:50px
}

@media only screen and (min-width: 1200px) {
  #main{ height: 100vh; overflow-y: scroll; scroll-snap-type:y mandatory; }
#main > section{scroll-snap-align: start;}
}

@media only screen and (max-width: 1199px) {  
  #main{ height: 100vh; overflow-y: scroll; scroll-snap-type:y mandatory; }
#main > section{scroll-snap-align: start;}
}
.leftsidebar{ background-color: white; transition: all 0.7s;   }
.whitecursor a, .whitecursor button{ cursor: url('/src/assets/cursor-white.png'), auto;}
.blackcursor a, .blackcursor button{ cursor: url('/src/assets/cursor-black.png'), auto;}
.whitecursor{ cursor: url('/src/assets/cursor-white.png'), auto;}
.blackcursor{ cursor: url('/src/assets/cursor-black.png'), auto;}
/* #111111 */
 .blacksidebar .leftsidebar{ background-color: #111111;} 
.whitesidebar .leftsidebar{ background-color: white;} 
.blacksidebar .mobilesidebar{ background-color: white;} 
.whitesidebar .mobilesidebar{ background-color: #111111;} 
/* .blacksidebar .leftsidebar{ background-color: transparent;} 
.whitesidebar .leftsidebar{ background-color:transparent;} */
.blacksidebar #navbar{ background-color:transparent;}
.whitesidebar #navbar{ background-color: transparent;}

.blacksidebar #menuBtn{color:white}
.whitesidebar #menuBtn{color:#111111}

.blacksidebar, .blacksidebar a, .swiper-pagination-bullet,.blacksidebar .swiper-button-next,.blacksidebar .swiper-button-prev{ cursor: url('/src/assets/cursor-white.png'), auto !important;}
.whitesidebar, .whitesidebar a,.whitesidebar .swiper-button-next,.whitesidebar .swiper-button-prev
{ cursor: url('/src/assets/cursor-black.png'), auto !important;}
#section3 .swiper-button-prev,#section3 .swiper-button-next{color:#aaa !important;  height:101%; top:0 !important; right: 0px !important; width:5% !important;      }
  #section3 .swiper-button-prev{ background: rgb(255,255,255) !important;
    background: linear-gradient(90deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%) !important; justify-content: flex-start; width:5% !important; padding-left:10px; left:0 !important; 
   
  }
  #section3 .swiper-button-next{justify-content: flex-end; padding-right: 10px; background: rgb(255,255,255) !important;
    background: linear-gradient(270deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%) !important;
   
  }
#section3 .swiper-button-prev.swiper-button-disabled, #section3 .swiper-button-next.swiper-button-disabled {
  opacity: 0 !important;
}


#section3  .swiper-button-next:after,#section3  .swiper-rtl .swiper-button-prev:after {
  background: url('assets/c-arrow-right.png') no-repeat center center;
  margin-right:20px;
  
}
#section3  .swiper-button-prev:after,#section3   .swiper-rtl .swiper-button-next:after {
  background: url('assets/c-arrow-left.png') no-repeat center center;
  margin-left:20px;
 
}

.client-btn-prev,  .client-btn-next{cursor: url('/src/assets/cursor-black.png'), auto;   z-index: 99; }
.client-btn-prev{background: linear-gradient(90deg, rgba(255,255,255,0.5) 65%, rgba(255,255,255,0) 100%); }
.client-btn-next{background: linear-gradient(270deg, rgba(255,255,255,0.5) 65%, rgba(255,255,255,0) 100%);}


.sidemenu a.menuitem:hover{font-size: 1.2em;  font-family:Baskervville, serif; font-style: italic;  text-transform: lowercase; color:#001aff; line-height:0.9em ;}
.sidemenu a.deskmenuitem:hover{font-size: 1.2em;  font-family:Baskervville, serif; font-style: italic;  text-transform: lowercase; color:#001aff; line-height:0.9em ;}

.sidemenu a:hover > .hoverremove,.sidemenu a.active > .hoverremove{display:none !important;  }
.sidemenu a.active{font-family:Baskervville, serif  !important; font-style: italic; color:#001aff; text-transform: lowercase; font-size: 1.2em; line-height: 0.9em;}

.menuitem.active, .deskmenuitem.active{  text-decoration: none;}
.menuitem:hover, .deskmenuitem:hover{  text-decoration: none;}
.blacksidebar .menuitem.active{ color:#001aff !important; text-decoration: none;}
.blacksidebar .deskmenuitem.active{ color:#00ff00 !important; text-decoration: none;}
.blacksidebar .menuitem:hover{ color:#001aff !important; text-decoration: none;}
.blacksidebar .deskmenuitem:hover{ color:#00ff00 !important; text-decoration: none;}
.blacksidebar .logotooltip{ color:#fff}

.blacksidebar .mobsidemenu a:hover{color:#000}
.mobsidemenu a:hover{color:#fff}
.mobsidemenu a.active{font-family:Baskervville, serif  !important; font-style: italic; color:#00ff00; text-transform: lowercase; font-size: 1.4em; line-height: 0.9em;}


.aboutus .siderborder{ }

#aboutuppout{direction: rtl;   overflow-y: auto; }
@media screen and (max-width: 767px) {
  #aboutuppout {margin-left: 10px;}
}
#aboutuppout .para{margin:25px 0; line-height: 1.2em; direction: ltr; } 
#aboutuppout .para:hover{ }

.line{position: relative;}
.line-mask{width: 100%; position: absolute; top: 0; right: 0; background-color: #111111;
opacity: 0.65; height: 100%; z-index: 2;
}

#aboutuppout::-webkit-scrollbar{width: 3px; height: 6px; }
#aboutuppout::-webkit-scrollbar-track{background:transparent url('../public/scrollbarbg.png') repeat-y; }
#aboutuppout::-webkit-scrollbar-thumb{
  background: #fff;
  width: 3px;    height: 6px; 
}

#aboutuppout::-webkit-scrollbar-button {
  height: 6px;  /* Reduce button height */
}
.swiper-scrollbar-horizontal{display: none;}


.bordery {
  border-top: 4px solid #00ff00;
  border-bottom: 4px solid #ff0000;
  border-left: 4px solid #00ff00;
  border-right: 4px solid #ff0000;
 
}

@media screen and (max-width: 767px) {
  .bordery {
    border-top: 3px solid #00ff00;
  border-bottom: 3px solid #ff0000;
  border-left: 3px solid #00ff00;
  border-right: 3px solid #ff0000;
  }
}

.bordery::after,
.bordery::before {
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  width: 8px;
}
.bordery::after {
  background: transparent url('../src/assets/border-corner-black.png') no-repeat;
  background-size: 100% 100%;
  transform: rotate(180deg);
  bottom: -4px;
  left: -4px;
}
.bordery::before {
  background: transparent url('../src/assets/border-corner-black.png') no-repeat;
  background-size: 100% 100%;
  top: -4px;
  right:-4px;
}


.cornerwhite::after {
  background: transparent url('../src/assets/border-corner-white.png') no-repeat;
}
.cornerwhite::before {
  background: transparent url('../src/assets/border-corner-white.png') no-repeat;
}


.servicebtn::after{content: "";
position: absolute;
display:block;  
border:solid 1px #aaa;
left:4px;
top:4px;
width:100%;
height:100%; margin:1px 2px 1px 1px
}

.section2 .swiper-button-next,.section2 .swiper-button-prev{top:40% !important}
@media screen and (max-width: 767px) {
  .section2 .swiper-button-next,.section2 .swiper-button-prev{top:25% !important}
}


